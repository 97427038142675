import * as Yup from 'yup';

Yup.setLocale({
  mixed: { required: "Don't be shy." },
  string: {
    email: 'You have entered wrong email address',
    min({ min }) {
      return `Must be at least ${min} characters`;
    },
    max({ max }) {
      return `Must be at most ${max} characters`;
    }
  }
});

export const userDetailsSchema = Yup.object().shape({
  name: Yup.string().required(),
  gender: Yup.string().required(),
  accept_tos_18: Yup.boolean().oneOf([true], 'You must accept the terms of service')
});

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string(),
  newPassword: Yup.string().min(6).required()
});

export const emailShcmea = Yup.object().shape({
  email: Yup.string().email().required()
});

export const otpSchema = Yup.object().shape({
  otp: Yup.string().required()
});

export const credentialsSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(6).required()
});

export const botDetailsSchema = Yup.object().shape({
  full_name: Yup.string()
    .test(
      'is-at-most-two-words',
      'Name must consist of at most two words with only alphanumeric characters, hyphens, and apostrophes.',
      (value) => {
        if (!value) return false;
        const words = value.trim().split(/\s+/);
        return words.length <= 2 && words.every((word) => /^[a-zA-Z0-9-']+$/.test(word));
      }
    )
    .required(),
  extroversion: Yup.number(),
  connection: Yup.number(),
  sex_drive: Yup.number()
});

export const becomeAffiliateSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  social_url: Yup.string(),
  telegram_id: Yup.string(),
  website: Yup.string(),
  join_reason: Yup.string(),
  promote_explanation: Yup.string().required(),
  tell_us_more: Yup.string()
});

export const customInstructionsSchema = (minLength: number) =>
  Yup.object().shape({
    custom_instructions: Yup.string().max(minLength)
  });
